import React from "react";
import styled from "styled-components";
import { RiChat1Line } from "react-icons/ri";
import { RiBookReadLine } from "react-icons/ri";
import { AiOutlinePhone } from "react-icons/ai";
import { AiOutlineFileText } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const StyledRiBookReadLine = styled(RiBookReadLine)`
  font-size: 30px;
  flex-shrink: 0;
  color: #707070 !important;
`;

const StyledAiOutlinePhone = styled(AiOutlinePhone)`
  font-size: 30px;
  flex-shrink: 0;
  color: #707070 !important;
`;

const StyledRiChat1Line = styled(RiChat1Line)`
  font-size: 30px;
  flex-shrink: 0;
  color: #707070 !important;
`;

const StyledAiOutlineFileText = styled(AiOutlineFileText)`
  font-size: 30px;
  flex-shrink: 0;
  color: #707070 !important;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 10px;

  @media (min-width: 576px) {
    column-gap: 15px;
  }

  @media (min-width: 768px) {
    column-gap: 20px;
  }

  @media (min-width: 992px) {
    justify-content: center;
  }
`;

const Box = styled.a`
  width: 48%;
  height: 185px;
  display: block;
  padding: 12px 24px;
  background-color: #e6efff;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(32, 64, 110, 0.1), ,
    0 2px 3px rgba(32, 64, 110, 0.2);

  &:hover {
    background-color: #d0e3f3;
    transition: background-color 0.2s ease-in-out;
  }

  @media (min-width: 576px) {
    height: 140px;
    width: 48%;
  }

  @media (min-width: 992px) {
    width: 25%;
    padding: 24px 30px;
    height: 205px;
  }

  @media (min-width: 1440px) {
    height: 160px;
  }
`;

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  font-size: 18px;
  color: #707070 !important;
  font-weight: 700;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const SubHeading = styled.div`
  font-size: 12px;
  color: #707070 !important;
  overflow-wrap: break-word;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const IndiaAIFestivalGuidelines = (props) => {
  const { t } = useTranslation();
  const { year } = props;

  return (
    <BoxWrapper>
      <Box
        href={`/india-ai-impact-festival-faqs${
          year === 2024 ? "" : `-${year}`
        }`}
      >
        <BoxContent>
          <StyledRiChat1Line />
          <Heading>{t("frequently_asked_questions")}</Heading>
        </BoxContent>
      </Box>
      <Box>
        <BoxContent>
          <StyledAiOutlinePhone />
          <Heading>{t("helpline")}</Heading>
          <SubHeading>aiimpactfestival@gmail.com</SubHeading>
        </BoxContent>
      </Box>
      <Box href={`/assets/pdf/iaiif-${year}-flyer.pdf`}>
        <BoxContent>
          <StyledAiOutlineFileText />
          <Heading>{t("flyer_text")}</Heading>
          <SubHeading>{t("donwload_here_text")}</SubHeading>
        </BoxContent>
      </Box>
    </BoxWrapper>
  );
};

export default IndiaAIFestivalGuidelines;
