import React from "react";
import Header from "../Header/header";
import Header2 from "../Header/secondheader";
import axios from "axios";
import ReactLoading from "react-loading";
import stateName from "../DataJson/IndianStates.json";
import { countries } from "../../constants/countries";

import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

// eslint-disable-next-line no-unused-vars
const options = [
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
  { value: 3, label: "Other" },
];

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      done: true,
      isAgreed: false,
      disableBtn: false,
      selectedGender: 1,
      selectedClass: null,
      selectedFile: null,
      selectedCountry: "",
      image: "",
      stateName: stateName,
      fileUploadButtonDisabled: false,
    };
  }

  createFileUploadNotification = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You look great!",
          "Picture uploaded!",
          3000
        );
        break;
      case "error":
        NotificationManager.error(
          "Please try again or come back later.",
          "Uh-oh, there was an error!",
          3000
        );
        break;
    }
  };

  createNotification = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Your profile is up to date.",
          "Submitted Successfully!",
          3000
        );
        break;
      case "error":
        NotificationManager.error(
          "Please try again or come back later.",
          "Uh-oh, there was an error!",
          3000
        );
        break;
    }
  };

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    if (this.state.selectedFile === null) {
      alert("Please select a image first.");
      return;
    } else {
      const fd1 = new FormData();
      this.setState({ fileUploadButtonDisabled: true });
      fd1.append("file", this.state.selectedFile, this.state.selectedFile.name);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/upload`,
          fd1,
          {
            headers: {
              "x-access-token": localStorage.getItem("jwtToken"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
          {
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
                done: false,
              });
            },
          }
        )
        .then((res) => {
          this.setState({
            image: res.data.path,
            done: true,
            fileUploadButtonDisabled: false,
          });
          if (res.status === 200) {
            this.createFileUploadNotification("success");
          } else {
            this.createFileUploadNotification("error");
          }
        });
    }
  };

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getProfile = () => {
    const id = localStorage.getItem("studentId");
    fetch(`${process.env.REACT_APP_API_URL}/users/students/` + id)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data,
            school_type: result.data[0].school_type,
            selectedGender: result.data[0].gender,
            selectedClass: result.data[0].class,
            selectedCountry: result.data[0].country,
            image: result.data[0].profile_pic,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  onSubmit = (e) => {
    e.preventDefault();

    const id = localStorage.getItem("studentId");
    fetch(`${process.env.REACT_APP_API_URL}/users/students/` + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: e.target.first_name.value,
        middle_name: e.target.middle_name.value,
        last_name: e.target.last_name.value,
        email_id: e.target.email.value,
        bio: e.target.bio.value,
        school_type: this.state.school_type,
        gender: this.state.selectedGender,
        school: e.target.school.value,
        city: e.target.city.value,
        state: this.state.selectedCountry === "IN" ? e.target.state.value : "",
        country: this.state.selectedCountry,
        profile_pic: this.state.image,
        class: this.state.selectedClass,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Student updated") {
          this.createNotification("success");
        } else {
          this.createNotification("error");
        }
      });
  };

  componentDidMount() {
    this.getProfile();
  }

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        {localStorage.getItem("jwtToken") == null ? <Header /> : <Header2 />}
        <div className="pt-265p pt-md-200p">
          <section className="hero-section  pb-50">
            <div className="container">
              <div className="col-md-12 col-lg-12 ">
                <div>
                  <h3>AI Student Community Profile</h3>
                  <p style={{ textAlign: "center" }}>
                    Complete the form below to sign up for joining the
                    community.
                  </p>
                </div>
              </div>

              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form className="login-signup-form" onSubmit={this.onSubmit}>
                    {data.map((data, index) => (
                      <div key={index}>
                        <div className="col-md-12 pl-0">
                          <label className="profile-container">
                            {!this.state.done ? (
                              <ReactLoading type={"bars"} color={"black"} />
                            ) : (
                              <div
                                className="profile-picture"
                                style={{
                                  backgroundImage: `url(${this.state.data.map(
                                    (item) => item.profile_pic
                                  )})`,
                                }}
                              ></div>
                            )}
                            <input
                              onChange={this.fileSelectedHandler}
                              type="file"
                              accept="image/*"
                              className="form-control"
                              style={{
                                display: "none",
                              }}
                            />
                          </label>
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  onChange={this.fileSelectedHandler}
                                  className="custom-file-input"
                                  id="inputGroupFile01"
                                  accept="image/*"
                                />
                                <label className="custom-file-label">
                                  {this.state.selectedFile
                                    ? this.state.selectedFile.name
                                    : "Choose new file"}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-4"
                            style={{
                              display: !this.state.selectedFile ? "none" : "",
                            }}
                          >
                            <button
                              type="button"
                              className={`category-btn mt-auto h-100 ${
                                this.state.fileUploadButtonDisabled
                                  ? "disabled"
                                  : ""
                              }`}
                              disabled={this.state.fileUploadButtonDisabled}
                              onClick={this.fileUploadHandler}
                            >
                              Select
                            </button>
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginTop: "30px",
                          }}
                        >
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="pb-1">First Name*</label>
                              <div className="input-group input-group-merge">
                                <input
                                  //value={this.state.first_name}
                                  defaultValue={data.first_name}
                                  onChange={this.onChange}
                                  type="text"
                                  name="first_name"
                                  className="form-control"
                                  placeholder="First Name"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="pb-1">Middle Name</label>
                              <div className="input-group input-group-merge">
                                <input
                                  defaultValue={data.middle_name}
                                  //value={this.state.middle_name}
                                  onChange={this.onChange}
                                  type="text"
                                  name="middle_name"
                                  className="form-control"
                                  placeholder="Middle Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-sm-4">
                            <label className="pb-1">Last Name*</label>
                            <div className="input-group input-group-merge">
                              <input
                                defaultValue={data.last_name}
                                //value={this.state.last_name}
                                onChange={this.onChange}
                                type="text"
                                name="last_name"
                                className="form-control"
                                placeholder="Last name"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="form-group col-sm-12"
                          style={{ paddingLeft: 0 }}
                        >
                          <label className="pb-1">Bio</label>
                          <div className="input-group input-group-merge">
                            <textarea
                              // value={this.state.bio}
                              defaultValue={data.bio}
                              onChange={this.onChange}
                              name="bio"
                              id="bio"
                              className="form-control"
                              rows="7"
                              cols="25"
                              placeholder="Bio"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-6">
                            <label className="pb-1">Email Address</label>
                            <div className="input-group-signup input-group-merge">
                              <input
                                defaultValue={data.email_id}
                                onChange={this.onChange}
                                //value={this.state.email}
                                id="email"
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="name@yourdomain.com"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="form-group col-sm-6">
                            <label className="pb-1">Contact Number*</label>
                            <div className="row">
                              <div className="col-sm-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="+91"
                                  defaultValue="+91"
                                  disabled
                                />
                              </div>
                              <div className="form-group col-sm-9">
                                <div className="input-group input-group-merge">
                                  <input
                                    defaultValue={data.contact_number}
                                    // value={this.state.contact_number}
                                    onChange={this.onChange}
                                    type="tel"
                                    name="contact_number"
                                    className="form-control"
                                    placeholder="123-456-7890"
                                    maxLength="10"
                                    minLength="10"
                                    pattern="[6-9]{1}[0-9]{9}"
                                    title="Please enter a valid phone number"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="form-group col-sm-6">
                            <label className="pb-1">Gender*</label>
                            <div className="input-group-signup input-group-merge">
                              <select
                                value={this.state.selectedGender}
                                className="form-control ml-0"
                                onChange={(event) =>
                                  this.setState({
                                    selectedGender: event.target.value,
                                  })
                                }
                                required
                              >
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                                <option value="3">Other</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group col-sm-6">
                            <label className="pb-1">
                              Class (As per academic year 2021-22)
                            </label>
                            <div className="input-group-signup input-group-merge">
                              <select
                                value={this.state.selectedClass}
                                className="form-control my-0 ml-0"
                                onChange={(event) => {
                                  this.setState({
                                    selectedClass: event.target.value,
                                  });
                                }}
                              >
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="Higher Education">
                                  Higher Education
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-6">
                            <label className="pb-1">
                              Name of your Institution*
                            </label>
                            <div className="input-group input-group-merge">
                              <input
                                defaultValue={data.school}
                                //value={this.state.school}
                                onChange={this.onChange}
                                type="text"
                                name="school"
                                className="form-control"
                                placeholder="Name of your Institution"
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="form-group col-sm-6">
                            <label className="pb-1">Institution Type</label>
                            <div className="input-group-signup input-group-merge">
                              <select
                                className="form-control mx-0"
                                defaultValue={this.state.school_type}
                                onChange={this.onChange}
                                id="school_type"
                              >
                                <option value="gov">Government</option>
                                <option value="private">Private</option>
                                <option value="govtaided">Govt. aided</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className={
                              this.state.selectedCountry === "IN"
                                ? "form-group col-sm-4"
                                : "form-group col-sm-6"
                            }
                          >
                            <label className="pb-1">Country*</label>
                            <div className="input-group-signup input-group-merge">
                              <select
                                name="country"
                                className="form-control ml-0 my-0"
                                value={this.state.country}
                                defaultValue={data.country}
                                onChange={(event) => {
                                  this.setState({
                                    selectedCountry: event.target.value,
                                  });
                                }}
                                required
                              >
                                <option value="">Select Country</option>
                                {countries.map((country, index) => (
                                  <option
                                    value={country.key}
                                    key={index}
                                    dangerouslySetInnerHTML={{
                                      __html: country.value,
                                    }}
                                  />
                                ))}
                              </select>
                            </div>
                          </div>
                          {this.state.selectedCountry === "IN" && (
                            <div className="form-group col-sm-4">
                              <label className="pb-1">State/UT*</label>
                              <div className="input-group-signup input-group-merge">
                                <select
                                  className="form-control ml-0 my-0"
                                  value={this.state.statename}
                                  defaultValue={data.state}
                                  name="state"
                                  onChange={this.onChange}
                                  required
                                >
                                  <option value="">Select State/UN</option>
                                  {this.state.stateName.state.map(
                                    (state, index) => (
                                      <option value={state.key} key={index}>
                                        {state.value}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          )}
                          <div
                            className={
                              this.state.selectedCountry === "IN"
                                ? "form-group col-sm-4"
                                : "form-group col-sm-6"
                            }
                          >
                            <label className="pb-1">City*</label>
                            <div className="input-group-signup input-group-merge">
                              <input
                                defaultValue={data.city}
                                // value={this.state.city}
                                onChange={this.onChange}
                                type="text"
                                name="city"
                                className="form-control"
                                placeholder="City"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row text-center">
                          <button
                            className="btn-blue"
                            id="profileSubmit"
                            disabled={this.state.disableBtn}
                            onClick={() => {
                              this.changeBtnText("Signing Up...");
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    ))}
                  </form>
                </div>
              </div>
            </div>
          </section>
          <NotificationContainer />
        </div>
      </React.Fragment>
    );
  }
}

export default Profile;
