import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import axios from "axios";
import { FaCheckSquare } from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import styled from "styled-components";
import Header from "../components/Header/header";
import SecondHeader from "../components/Header/secondheader";
import Footer from "../components/Footer/index";
import { IndiaAIFestivalDeadlineNotice } from "../components/IndiaAIFestival";
import { BlinkingNode, LanguageSwitcher } from "../components/shared";
import { isSubmissionOpen } from "../utils/impactFestival";

const StyledCheckIcon = styled(FaCheckSquare)`
  color: var(--success);
  font-size: 16px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const aspirationalDistt = [
  "Alluri Sitharamaraju",
  "Parvathipuram Manyam",
  "Y.S.R. Kadapa",
  "Namsai",
  "Baksa",
  "Barpeta",
  "Darrang",
  "Dhubri",
  "Goalpara",
  "Hailakandi",
  "Udalguri",
  "Araria",
  "Aurangabad",
  "Banka",
  "Begusarai",
  "Gaya",
  "Jamui",
  "Katihar",
  "Khagaria",
  "Muzaffarpur",
  "Nawada",
  "Purnea",
  "Sheikhpura",
  "Sitamarhi",
  "Bastar",
  "Bijapur",
  "Dantewada",
  "Kanker",
  "Kondagaon",
  "Korba",
  "Mahasamund",
  "Narayanpur",
  "Rajnandgaon",
  "Sukma",
  "Dahod",
  "Narmada",
  "Mewat",
  "Chamba",
  "Baramula",
  "Kupwara",
  "Bokaro",
  "Chatra",
  "Dumka",
  "Garhwa",
  "Giridih",
  "Godda",
  "Gumla",
  "Hazaribag",
  "Khunti",
  "Latehar",
  "Lohardaga",
  "Pakur",
  "Palamu",
  "Pashchimi Singhbhum",
  "Purbi Singhbhum",
  "Ramgarh",
  "Ranchi",
  "Sahibganj",
  "Simdega",
  "Raichur",
  "Yadgir",
  "Wayanad",
  "Barwani",
  "Chhatarpur",
  "Damoh",
  "Guna",
  "Khandwa",
  "Rajgarh",
  "Singrauli",
  "Vidisha",
  "Gadchiroli",
  "Nandurbar",
  "Osmanabad",
  "Washim",
  "Chandel",
  "Ribhoi",
  "Mamit",
  "Kiphire",
  "Balangir",
  "Dhenkanal",
  "Gajapati",
  "Kalahandi",
  "Kandhamal",
  "Koraput",
  "Malkangiri",
  "Nabarangapur",
  "Nuapada",
  "Rayagada",
  "Ferozepur",
  "Moga",
  "Baran",
  "Dholpur",
  "Jaisalmer",
  "Karauli",
  "Sirohi",
  "Soreng",
  "Ramanathapuram",
  "Virudhunagar",
  "Asifabad",
  "Bhadradri-Kothagudem",
  "Bhupalpally",
  "Dhalai",
  "Bahraich",
  "Balrampur",
  "Chandauli",
  "Chitrakoot",
  "Fatehpur",
  "Shravasti",
  "Siddharthnagar",
  "Sonbhadra",
  "Haridwar",
  "Udham Singh Nagar",
];

const unSdgs = [
  "No Poverty",
  "Zero Hunger",
  "Good Health and Well-Being",
  "Quality Education",
  "Gender Equality",
  "Clean Water and Sanitization",
  "Affordable and Clean Energy",
  "Decent Work and Economic Growth",
  "Industry, Innovation and Infrastructure",
  "Reduced Inequalities",
  "Sustainable Cities and Communities",
  "Responsible Consumption and Production",
  "Climate Action",
  "Life Below Water",
  "Life on Land",
  "Peace, Justice and Strong Institutions",
  "Partnerships for the Goals",
];

const projectTypes = ["Individual", "Group"];

const categories = [
  { label: "Impact Creators", value: "impactCreators" },
  { label: "Impact Shapers", value: "impactShapers" },
  { label: "Impact Nurturers", value: "impactNurturers" },
];

const subCategories = {
  impactCreators: [
    { label: "Student of Class 6 to 8", value: "class_secondary" },
    { label: "Student of Class 9 to 12", value: "class_sr_secondary" },
    { label: "Youth of age-group 18 to 25 years", value: "class_higher" },
  ],
  impactShapers: [
    { label: "Educators from schools, colleges", value: "educators_school" },
    { label: "ATL in-charges", value: "atl_incharges" },
  ],
  impactNurturers: [],
};

const specialAwards = {
  impactCreators: [
    {
      label: "Nari Shakti",
      value: "nari_shakti",
      options: [
        {
          label: "Project by individual girl student",
          value: "entry_girl",
        },
        {
          label: "Project by team of girl students",
          value: "entry_girl_team",
        },
      ],
    },
    {
      label: "Accessibility",
      value: "accessibility",
      options: [
        {
          label:
            "Project removes/reduces barriers for people with special needs",
          value: "entry_special_needs",
        },
      ],
    },
    {
      label: "Diversity",
      value: "diversity",
      options: [
        {
          label: "Entry from Aspirational District",
          value: "entry_aspirational_distt",
        },
      ],
    },
  ],

  impactShapers: [
    {
      label: "Diversity",
      value: "diversity",
      options: [
        {
          label: "Best practices leveraging AIoT",
          value: "entry_best_practices_aiot",
        },
      ],
    },
  ],
};

const IndiaAIFestivalRegistrationPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const year = 2024;

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("jwtToken");

        const headers = {
          "x-access-token": token,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/impact-festival/entry`,
          { headers }
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const createFileUploadNotification = (type) => {
    switch (type) {
      case "submit_success":
        NotificationManager.success(
          "Project submitted!",
          "Congratulations! You have successfully submit your project for evaluatation.",
          3000
        );
        break;

      case "save_success":
        NotificationManager.success(
          "Congratulations!",
          "Your entry has been submitted successfully.",
          6000
        );
        break;

      default:
        NotificationManager.error(
          "Please try again or come back later.",
          "Uh-oh, there was an error!",
          6000
        );
        break;
    }
  };

  const editable = data?.status !== "confirmed";

  const RegistrationSchema = Yup.object().shape({
    category: Yup.string().required(t("validation.category")),
    subCategory: Yup.string().when(["category"], {
      is: (category) => {
        return !!category && subCategories[category].length > 0;
      },
      then: (schema) => schema.required(t("validation.sub_category")),
    }),
    eligible: Yup.string().when(["category"], {
      is: (category) => !!category && specialAwards[category]?.length > 0,
      then: (schema) =>
        schema
          .required(t("validation.eligible"))
          .oneOf(["yes", "no"], t("validation.eligible")),
    }),

    readGuideline: Yup.boolean()
      .oneOf([true], t("validation.read_guideline"))
      .required(t("validation.read_guideline")),

    specialAwardCategory: Yup.string().when(["category", "eligible"], {
      is: (category, eligible) =>
        !!category && specialAwards[category]?.length > 0 && eligible === "yes",
      then: (schema) => schema.required(t("validation.special_award_category")),
    }),

    specialAwardSubCategory: Yup.string().when("eligible", {
      is: "yes",
      then: (schema) =>
        schema.required(t("validation.special_award_sub_category")),
    }),
    sdg: Yup.string()
      .required(t("validation.sdg"))
      .test("isNotPlaceholder", t("validation.sdg"), (value) => value !== ""),

    projectPDF: Yup.mixed()
      .required(t("validation.project_pdf"))
      .test(
        "fileType",
        t("validation.project_pdf_file_type"),
        (value) => value && value.type === "application/pdf"
      )
      .test(
        "fileSize",
        t("validation.project_pdf_file_size"),
        (value) => value && value.size <= 5 * 1024 * 1024 // 5MB in bytes
      ),
    projectVideo: Yup.mixed().when("projectVideoURL", {
      is: (projectVideoURL) => !projectVideoURL,
      then: (schema) =>
        schema
          .required(t("validation.either_project_video_or_url"))
          .test(
            "fileType",
            t("validation.project_video_file_type"),
            (value) =>
              value &&
              (value.type === "video/mp4" ||
                value.type === "video/quicktime" ||
                value.type === "video/x-ms-wmv" ||
                value.type === "video/x-flv" ||
                value.type === "video/x-msvideo")
          )
          .test(
            "fileSize",
            t("validation.project_video_file_size"),
            (value) => value && value.size <= 25 * 1024 * 1024 // 25MB in bytes
          ),
    }),
    projectVideoURL: Yup.string().url(t("validation.project_video_url")),
    aspirationalDistt: Yup.string().when(
      [
        "category",
        "eligible",
        "specialAwardCategory",
        "specialAwardSubCategory",
      ],
      {
        is: (
          category,
          eligible,
          specialAwardCategory,
          specialAwardSubCategory
        ) =>
          !!category &&
          specialAwards[category]?.length > 0 &&
          eligible === "yes" &&
          specialAwardCategory === "diversity" &&
          specialAwardSubCategory === "entry_aspirational_distt",
        then: (schema) => schema.required(t("validation.aspirational_distt")),
      }
    ),

    projectMember1: Yup.string().when(["projectType"], {
      is: (projectType) => {
        return projectType && projectType === "Group";
      },
      then: (schema) => schema.required(t("validation.project_member_1")),
    }),
  });

  return (
    <>
      {localStorage.getItem("jwtToken") == null ? <Header /> : <SecondHeader />}
      {isSubmissionOpen(year) ? (
        <div className="iaiif-registration-page gray-light-bg">
          <div class="d-flex justify-content-center w-100">
            <BlinkingNode>
              <IndiaAIFestivalDeadlineNotice />
            </BlinkingNode>
          </div>
          <div className="container d-flex justify-content-end">
            <LanguageSwitcher />
          </div>
          <div className="blog-container shadow">
            <div className="container">
              <div className="row user-info-container">
                <div className="col-md-12">
                  <h1 className="text-center">
                    {t("submission_page_title").replace("{year}", year)}
                  </h1>
                  <h2 className="text-center">
                    {t("submission_page_subtitle")}
                  </h2>
                </div>
              </div>
              <div>
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center py-5">
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">{t("loading_text")}</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    {data.status === "confirmed" && (
                      <div className="alert alert-success">
                        {t("submission_page_confirmation")}
                      </div>
                    )}

                    <div className="p-2">
                      <p className="text-muted">
                        {t("submission_page_disclaimer_part_1")}{" "}
                        <a
                          href="/india-ai-impact-festival-faqs"
                          className="text-primary"
                        >
                          {t("submission_page_disclaimer_link_text_faq")}
                        </a>{" "}
                        {t("submission_page_disclaimer_part_2")}.
                      </p>
                    </div>

                    <Formik
                      initialValues={{
                        sdg: data.sdg,
                        category: data.category,
                        subCategory: data.subCategory || "",
                        eligible: data.eligible || "",
                        specialAwardCategory: data.specialAwardCategory || "",
                        specialAwardSubCategory:
                          data.specialAwardSubCategory || "",
                        projectPDF: data.projectPDF,
                        projectVideo: data.projectVideo,
                        projectVideoURL: "",
                        aspirationalDistt: data.aspirationalDistt || "",
                        ethicalConcern: data.ethicalConcern,
                        projectType: data.projectType,
                        projectMember1: data.projectMember1 || "",
                        projectMember2: data.projectMember2 || "",
                        projectMember3: data.projectMember3 || "",
                        referralCode: data.referralCode || "",
                        readGuideline: false,
                      }}
                      validationSchema={RegistrationSchema}
                      onSubmit={async (values) => {
                        const confirmed = window.confirm(
                          t("submission_page_confirmation_text")
                        );

                        if (confirmed) {
                          const formData = new FormData();

                          formData.append("category", values.category);
                          formData.append("subCategory", values.subCategory);
                          formData.append("referralCode", values.referralCode);
                          formData.append("eligible", values.eligible);
                          formData.append(
                            "specialAwardCategory",
                            values.specialAwardCategory
                          );
                          formData.append(
                            "specialAwardSubCategory",
                            values.specialAwardSubCategory
                          );
                          formData.append(
                            "aspirationalDistt",
                            values.aspirationalDistt
                          );
                          formData.append("sdg", values.sdg);
                          formData.append(
                            "ethicalConcern",
                            values.ethicalConcern
                          );
                          formData.append("projectVideo", values.projectVideo);
                          formData.append(
                            "projectVideoURL",
                            values.projectVideoURL
                          );
                          formData.append("projectPDF", values.projectPDF);
                          formData.append("projectType", values.projectType);
                          formData.append("readGuideline", values.guidelines);
                          formData.append(
                            "projectMember1",
                            values.projectMember1
                          );
                          formData.append(
                            "projectMember2",
                            values.projectMember2
                          );
                          formData.append(
                            "projectMember3",
                            values.projectMember3
                          );

                          const source = localStorage.getItem("iaiif_source");

                          if (source) {
                            formData.append("source", source);
                          }

                          try {
                            const response = await axios.post(
                              `${process.env.REACT_APP_API_URL}/impact-festival/confirm`,
                              formData,
                              {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                  "x-access-token":
                                    localStorage.getItem("jwtToken"),
                                },
                              }
                            );
                            createFileUploadNotification("save_success");
                            setData(response.data.data);
                          } catch (error) {
                            createFileUploadNotification("error");
                          }
                        }
                      }}
                    >
                      {({
                        setFieldValue,
                        values,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form>
                          <div className="form-row">
                            <div class="col-md-6 px-3 mb-3">
                              <div className="form-group">
                                <label htmlFor="studentName">
                                  {t("submission_page_label_name")}
                                </label>

                                <Field
                                  type="text"
                                  name="studentName"
                                  className="form-control bg-light m-0"
                                  id="studentName"
                                  placeholder={`${data.first_name} ${data.last_name}`}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          {/* Category field */}
                          <div className="form-row">
                            <div class="col-md-6 px-3 mb-3">
                              <div className="form-group">
                                <label htmlFor="category">
                                  {t("submission_page_label_category")}
                                  <small className="text-danger">*</small>
                                </label>

                                <Field
                                  as="select"
                                  name="category"
                                  className="form-control m-0"
                                  id="category"
                                  disabled={!editable}
                                >
                                  <option value="">
                                    {t(
                                      "submission_page_label_category_option_blank"
                                    )}
                                  </option>
                                  {categories.map((category) => (
                                    <option
                                      key={category.value}
                                      value={category.value}
                                    >
                                      {t(
                                        `submission_page_category_options.${category.value}`
                                      )}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="category"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            {values.category &&
                              subCategories[values.category].length > 0 && (
                                <div class="col-md-6 px-3 mb-3">
                                  <div className="form-group">
                                    <label htmlFor="subCategory">
                                      {t("submission_page_label_sub_category")}
                                      <small className="text-danger">*</small>
                                    </label>
                                    <Field
                                      as="select"
                                      name="subCategory"
                                      className="form-control m-0"
                                      id="subCategory"
                                      disabled={!editable}
                                    >
                                      <option value="">
                                        {t(
                                          "submission_page_label_sub_category_option_blank"
                                        )}
                                      </option>
                                      {subCategories[values.category].map(
                                        (category) => (
                                          <option
                                            key={category.value}
                                            value={category.value}
                                          >
                                            {t(
                                              `submission_page_sub_category_options.${category.value}`
                                            )}
                                          </option>
                                        )
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="subCategory"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                              )}
                          </div>

                          {values.category &&
                            specialAwards[values.category] && (
                              <>
                                <div className="form-row">
                                  <div class="col-md-4 mb-3 px-3 mb-md-0">
                                    <label>
                                      {t("submission_page_label_special_award")}
                                      <small className="text-danger">*</small>
                                    </label>
                                    <div className="form-group">
                                      <div className="form-check form-check-inline">
                                        <Field
                                          type="radio"
                                          name="eligible"
                                          value="yes"
                                          id="eligible-yes"
                                          className="form-check-input"
                                          disabled={!editable}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="eligible-yes"
                                        >
                                          {t(
                                            "submission_page_special_award_option_yes"
                                          )}
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <Field
                                          type="radio"
                                          name="eligible"
                                          value="no"
                                          id="eligible-no"
                                          className="form-check-input"
                                          disabled={!editable}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="eligible-no"
                                        >
                                          {t(
                                            "submission_page_special_award_option_no"
                                          )}
                                        </label>
                                      </div>
                                      <ErrorMessage
                                        name="eligible"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-row">
                                  {values.eligible === "yes" && (
                                    <div className="col-md-6 px-3 mb-3">
                                      <div className="form-group">
                                        <label htmlFor="specialAwardCategory">
                                          <label
                                            className="form-check-label"
                                            htmlFor="eligible-no"
                                          >
                                            {t(
                                              "submission_page_label_special_award_category"
                                            )}
                                          </label>

                                          <small className="text-danger">
                                            *
                                          </small>
                                        </label>
                                        <Field
                                          as="select"
                                          name="specialAwardCategory"
                                          className="form-control m-0"
                                          id="specialAwardCategory"
                                          disabled={!editable}
                                        >
                                          <option value="">
                                            {t(
                                              "submission_page_special_award_category_option_blank"
                                            )}
                                          </option>
                                          {specialAwards[values.category].map(
                                            (category) => (
                                              <option
                                                key={category.value}
                                                value={category.value}
                                              >
                                                {t(
                                                  `submission_page_special_award_category_options.${category.value}`
                                                )}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          name="specialAwardCategory"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {values.eligible === "yes" &&
                                    values.specialAwardCategory && (
                                      <div className="col-md-6 px-3 mb-3">
                                        <div className="form-group">
                                          <label htmlFor="specialAwardSubCategory">
                                            {t(
                                              "submission_page_label_special_award_sub_category"
                                            )}
                                            <small className="text-danger">
                                              *
                                            </small>
                                          </label>
                                          <Field
                                            as="select"
                                            name="specialAwardSubCategory"
                                            className="form-control m-0"
                                            id="specialAwardSubCategory"
                                            disabled={!editable}
                                          >
                                            <option value="">
                                              {t(
                                                "submission_page_special_award_sub_category_option_blank"
                                              )}
                                            </option>
                                            {specialAwards[values.category]
                                              .find(
                                                (x) =>
                                                  x.value ===
                                                  values.specialAwardCategory
                                              )
                                              ?.options.map((category) => (
                                                <option
                                                  key={category.value}
                                                  value={category.value}
                                                >
                                                  {t(
                                                    `submission_page_special_award_sub_category_options.${category.value}`
                                                  )}
                                                </option>
                                              ))}
                                          </Field>
                                          <ErrorMessage
                                            name="specialAwardSubCategory"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                    )}

                                  {values.eligible === "yes" &&
                                    values.specialAwardCategory ===
                                      "diversity" &&
                                    values.specialAwardSubCategory ===
                                      "entry_aspirational_distt" && (
                                      <div className="col-md-6 px-3 mb-3">
                                        <div className="form-group">
                                          <label htmlFor="aspirationalDistt">
                                            {t(
                                              "submission_page_label_aspirational_distt"
                                            )}
                                            <small className="text-danger">
                                              *
                                            </small>
                                          </label>
                                          <Field
                                            as="select"
                                            name="aspirationalDistt"
                                            className="form-control m-0"
                                            id="aspirationalDistt"
                                            disabled={!editable}
                                          >
                                            <option value="">
                                              {t(
                                                "submission_page_aspirational_distt_option_blank"
                                              )}
                                            </option>
                                            {aspirationalDistt.map(
                                              (distt, index) => (
                                                <option
                                                  key={distt}
                                                  value={distt}
                                                >
                                                  {t(
                                                    "submission_page_aspirational_distt_options." +
                                                      index
                                                  )}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="aspirationalDistt"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </>
                            )}

                          <div className="form-row">
                            <div className="col-md-6 px-3 mb-3">
                              <div className="form-group">
                                <label htmlFor="sdg">
                                  {t("submission_page_label_sdg")}
                                  <small className="text-danger">*</small>
                                </label>
                                <Field
                                  as="select"
                                  name="sdg"
                                  className="form-control m-0"
                                  id="sdg"
                                  disabled={!editable}
                                >
                                  <option value="">
                                    {t("submission_page_sdg_option_blank")}
                                  </option>
                                  {unSdgs.map((sdg, idx) => (
                                    <option
                                      key={sdg}
                                      value={`SDG ${idx + 1}: ${sdg}`}
                                    >
                                      {`SDG ${idx + 1}: ${t(
                                        "submission_page_sdg_options." + idx
                                      )}`}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="sdg"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-md-6 px-3 mb-3">
                              <div className="form-group">
                                <label htmlFor="ethicalConcern">
                                  {t("submission_page_label_ethical_concern")}
                                </label>
                                <Field
                                  type="text"
                                  name="ethicalConcern"
                                  className="form-control m-0"
                                  id="ethicalConcern"
                                  disabled={!editable}
                                  placeholder={t(
                                    "submission_page_label_ethical_concern_placeholder"
                                  )}
                                />
                                <ErrorMessage
                                  name="ethicalConcern"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          {values.category === "impactCreators" && (
                            <div className="form-row">
                              <div className="col-md-6 px-3 mb-3">
                                <div className="form-group">
                                  <label htmlFor="projectType">
                                    {t("submission_page_label_project_type")}
                                    <small className="text-danger">*</small>
                                  </label>
                                  <Field
                                    as="select"
                                    name="projectType"
                                    className="form-control m-0"
                                    id="projectType"
                                    disabled={!editable}
                                  >
                                    <option value="">
                                      {t(
                                        "submission_page_project_type_option_blank"
                                      )}
                                    </option>
                                    {projectTypes.map((projectType, index) => (
                                      <option
                                        key={projectType}
                                        value={projectType}
                                      >
                                        {t(
                                          "submission_page_project_type_options." +
                                            index
                                        )}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="projectType"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>

                              {values.projectType === "Group" && (
                                <div className="col-md-6 px-3 mb-3">
                                  <div className="form-group">
                                    <label htmlFor="projectMember1">
                                      Name and class of team members (max 3)
                                      <small className="text-danger">*</small>
                                    </label>
                                    <Field
                                      type="text"
                                      name="projectMember1"
                                      className="form-control m-0 mb-2"
                                      id="projectMember1"
                                      disabled={!editable}
                                      placeholder="Team member name and class"
                                    />
                                    <ErrorMessage
                                      name="projectMember1"
                                      component="div"
                                      className="text-danger"
                                    />
                                    <Field
                                      type="text"
                                      name="projectMember2"
                                      className="form-control m-0 mb-2"
                                      id="projectMember2"
                                      disabled={!editable}
                                      placeholder="Team member name and class"
                                    />
                                    <Field
                                      type="text"
                                      name="projectMember3"
                                      className="form-control m-0 mb-2"
                                      id="projectMember3"
                                      disabled={!editable}
                                      placeholder="Team member name and class"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="form-row">
                            <div className="col-md-6 px-3 mb-3">
                              <div className="form-group">
                                <label htmlFor="projectPDF">
                                  {t("submission_page_label_submit_pdf_1")}{" "}
                                  {values.category === "impactCreators"
                                    ? t(
                                        "submission_page_label_submit_pdf_project"
                                      )
                                    : t(
                                        "submission_page_label_submit_pdf_poster"
                                      )}
                                  , {t("submission_page_label_submit_pdf_2")}:
                                  <small className="text-danger">*</small>
                                </label>

                                <small className="form-text">
                                  {t("submission_page_submit_pdf_description")}
                                </small>

                                {data.projectPDF && (
                                  <FlexCenter>
                                    <StyledCheckIcon />{" "}
                                    <small className="text-success ml-1">
                                      {t(
                                        "submission_page_label_submitted_text"
                                      )}
                                    </small>
                                  </FlexCenter>
                                )}

                                {editable && (
                                  <>
                                    <input
                                      id="projectPDF"
                                      name="projectPDF"
                                      type="file"
                                      onChange={(event) =>
                                        setFieldValue(
                                          "projectPDF",
                                          event.currentTarget.files[0]
                                        )
                                      }
                                      className="form-control-file mt-2"
                                      disabled={!editable}
                                    />
                                    <small class="form-text text-muted">
                                      {t(
                                        "submission_page_submit_pdf_requirement"
                                      )}
                                    </small>
                                  </>
                                )}

                                <ErrorMessage
                                  name="projectPDF"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-md-6 px-3 mb-3">
                              <div className="form-group">
                                <label htmlFor="projectVideo">
                                  {t("submission_page_label_submit_video")}
                                  <small className="text-danger">*</small>
                                </label>
                                <small className="form-text">
                                  {t(
                                    "submission_page_submit_video_description"
                                  )}
                                </small>

                                {data.projectVideo && (
                                  <FlexCenter>
                                    <StyledCheckIcon />{" "}
                                    <small className="text-success ml-1">
                                      {t(
                                        "submission_page_label_submitted_text"
                                      )}
                                    </small>
                                  </FlexCenter>
                                )}

                                {editable && (
                                  <>
                                    <input
                                      id="projectVideo"
                                      name="projectVideo"
                                      type="file"
                                      onChange={(event) =>
                                        setFieldValue(
                                          "projectVideo",
                                          event.currentTarget.files[0]
                                        )
                                      }
                                      className="form-control-file mt-2"
                                      disabled={!editable}
                                    />
                                    <small class="form-text text-muted">
                                      {t(
                                        "submission_page_submit_video_requirement"
                                      )}
                                    </small>
                                  </>
                                )}

                                <ErrorMessage
                                  name="projectVideo"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              {editable && (
                                <div className="form-group">
                                  <label htmlFor="projectVideoURL">
                                    {t(
                                      "submission_page_label_submit_video_url"
                                    )}
                                  </label>
                                  <small className="form-text">
                                    {t(
                                      "submission_page_submit_video_url_description"
                                    )}
                                  </small>
                                  <>
                                    <Field
                                      type="text"
                                      name="projectVideoURL"
                                      className="form-control m-0 mb-2"
                                      id="projectVideoURL"
                                      disabled={!editable}
                                      placeholder="E.g., https://www.youtube.com/watch?v=HGYYEUSm-0Q"
                                    />
                                  </>
                                  <ErrorMessage
                                    name="projectVideoURL"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-md-6 px-3 mb-3">
                              <div className="form-group">
                                <label htmlFor="referralCode">
                                  {t("submission_page_label_referral_code")}
                                </label>
                                <Field
                                  type="text"
                                  name="referralCode"
                                  className="form-control m-0"
                                  id="referralCode"
                                  disabled={!editable}
                                />
                                <ErrorMessage
                                  name="referralCode"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div class="col-12 px-3 mb-md-0">
                              <div className="form-group">
                                <div className="form-check form-check-inline">
                                  <Field
                                    type="checkbox"
                                    disabled={!editable}
                                    className="form-check-input"
                                    name="readGuideline"
                                    id="readGuideline"
                                  />
                                  <label
                                    htmlFor="readGuideline"
                                    className="form-check-label"
                                  >
                                    {t("submission_page_terms_check_text")}{" "}
                                    <a
                                      href="/india-ai-impact-festival-faqs"
                                      className="text-primary"
                                    >
                                      <strong>
                                        {t(
                                          "submission_page_disclaimer_link_text_faq"
                                        )}
                                      </strong>
                                    </a>
                                    .
                                  </label>
                                </div>
                                <ErrorMessage
                                  name="readGuideline"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          {editable && (
                            <>
                              <div className="mt-2">
                                {/*<button
                                type="button"
                                onClick={() => {}}
                                className="btn btn-secondary mr-3"
                              >
                                Save progress
                              </button>*/}
                                <div>
                                  <button
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    className="btn btn-primary mr-3 mt-3 mt-md-0 "
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span
                                        class="spinner-grow spinner-grow-sm mr-2"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                    {isSubmitting
                                      ? t("submission_page_submitting_btn_text")
                                      : t("submission_page_submit_btn_text")}
                                  </button>
                                </div>
                                {values.category && (
                                  <small className="text-muted font-italic pt-2">
                                    {t("submission_page_download_text")}{" "}
                                    <a
                                      className="text-black"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        values.category === "impactCreators"
                                          ? "/assets/pdf/evaluation-impact-creators.pdf"
                                          : "/assets/pdf/evaluation-impact-shapers-nurturers.pdf"
                                      }
                                    >
                                      <strong>
                                        {t(
                                          "submission_page_evaluation_params_text"
                                        )}
                                      </strong>
                                    </a>
                                    .
                                  </small>
                                )}
                              </div>
                            </>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
          <NotificationContainer />
        </div>
      ) : (
        <div className="iaiif-registration-page gray-light-bg footer-sticky-bottom">
          <div className="blog-container shadow">
            <div className="container">
              <div className="row align-items-center user-info-container">
                <div className="col-md-12">
                  <h1 className="text-center">{t("submission_page_title")}</h1>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-9 col-xl-7">
                  <div className="alert alert-info mx-auto my-3 my-md-5">
                    Submissions closed.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default IndiaAIFestivalRegistrationPage;
