import React from "react";
import styled from "styled-components";
import { isSubmissionOpen } from "../../utils/impactFestival";

const Container = styled.div`
  display: inline-block;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 1.5rem;
  background: linear-gradient(
    to right,
    #90ee90,
    #add8e6
  ); /* Green gradient for relief */
`;

const IndiaAIFestivalDeadlineNotice = (props) => {
  const { year, deadline } = props;
  return (
    <Container>
      {isSubmissionOpen(year) ? (
        <p className="mb-0 text-center">
          The last date of submissions has been extended to {deadline}!
          <br />
          <hr className="my-2" />
          Hurry! It's your last chance to participate in India's Biggest AI
          Impact Festival.
        </p>
      ) : (
        <p className="mb-0 text-center">
          Submission closed for India AI Impact Festival {year}.
        </p>
      )}
    </Container>
  );
};

export default IndiaAIFestivalDeadlineNotice;
